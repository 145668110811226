import React from "react"
import ContactFormStyle from "./ContactFormStyle.module.styl"
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
config.autoAddCss = false

const ContactForm = ({ state, handleInput }) => {
  return (
    <section id="contacts" className={ContactFormStyle.contact_section}>
      <div className={ContactFormStyle.contact_form_wrapper}>
        <div className={ContactFormStyle.contact_form_holder}>
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            action="/success"
            data-netlify-honeypot="bot-field"
            className={ContactFormStyle.contact_form}
          >
            <input type="hidden" name="form-name" value="contact" />
            <h2>Свържете се с нас</h2>
            <p>Ако имаш някакви въпроси, изпрати ни съобщение.</p>
            <label htmlFor="name">Име</label>
            <input
              name="name"
              value={state.name}
              onChange={handleInput}
              type="text"
              placeholder="Въведете име"
            />

            <label htmlFor="telephone">Телефон </label>
            <input
              name="telephone"
              value={state.telephone}
              onChange={handleInput}
              type="number"
              placeholder="Въведете телефонен номер"
            />

            <label htmlFor="email">Имейл адрес </label>
            <input
              name="email"
              type="text"
              value={state.email}
              onChange={handleInput}
              placeholder="Въведете имейл адрес"
            />

            <label htmlFor="message">Съобщение </label>
            <textarea
              onChange={handleInput}
              value={state.message}
              name="message"
              placeholder="Въведете съобщение"
              rows={3}
              cols={3}
            />

            {state.name.length < 1 ||
            state.email.length < 1 ||
            state.telephone.length < 10 ||
            state.telephone.length > 10 ||
            state.message.length < 1 ? (
              <button
                disabled={true}
                className={ContactFormStyle.disabled_contact_us_btn}
              >
                ИЗПРАТИ
              </button>
            ) : (
              <button type="submit" className={ContactFormStyle.contact_us_btn}>
                ИЗПРАТИ
              </button>
            )}
          </form>
          <div className={ContactFormStyle.contact_info}>
            <div className={ContactFormStyle.contact_info_holder}>
              <h2>Контакти</h2>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/place/%D1%83%D0%BB.+%E2%80%9E%D0%A2%D1%8A%D1%80%D0%B3%D0%BE%D0%B2%D1%81%D0%BA%D0%B0%E2%80%9C+164,+8602+%D0%B6.%D0%BA.+%D0%A5%D0%B0%D0%BB%D0%B5,+%D0%AF%D0%BC%D0%B1%D0%BE%D0%BB/@42.4757817,26.5282116,17z/data=!4m8!1m2!2m1!1z0YPQuy4g4oCe0KLRitGA0LPQvtCy0YHQutCw4oCcIDE2NCDRj9C80LHQvtC7!3m4!1s0x40a635c8716c91f9:0x989ab0e545d25990!8m2!3d42.4755244!4d26.5281011"
                className={ContactFormStyle.contact_item}
              >
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <span>гр.Ямбол, ул. Търговска #164 ет.4 офис 11</span>
              </a>{" "}
              <div className={ContactFormStyle.contact_item}>
                <FontAwesomeIcon icon={faPhoneAlt} />
                <div className={ContactFormStyle.telephones_wrapper}>
                  <a href="tel:0893 068 000">0893 068 000</a>
                  <a href="tel:0893 068 999">0893 068 999</a>
                </div>
              </div>{" "}
              <a
                href="mailto:ssgroupbg@gmail.com"
                className={ContactFormStyle.contact_item}
              >
                <FontAwesomeIcon icon={faEnvelope} />
                <span>ssgroupbg@gmail.com</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactForm
