import React, { useEffect, useState } from "react"
import ContactForm from "./contactForm/ContactForm"

const ContactsTemplate = () => {
  const [state, setState] = useState({
    name: "",
    email: "",
    telephone: "",
    message: "",
  })

  const handleInput = ({ target: { name, value } }) => {
    setState({ ...state, [name]: value })
  }
  return (
    <>
      <ContactForm
        state={state}
        handleInput={handleInput}
      />
    </>
  )
}

export default ContactsTemplate
