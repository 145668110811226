import React from "react"
import ContactsHeroSectionStyle from "./ContactsHeroSectionStyle.module.styl"
import Img from "gatsby-image"

const ContactsHeroSection = ({ contactsHeroSectionImage }) => {
  return (
    <section className={ContactsHeroSectionStyle.hero_section}>
      <Img
        className={ContactsHeroSectionStyle.image}
        fluid={contactsHeroSectionImage[0].node.childImageSharp.fluid}
      />
      <div className={ContactsHeroSectionStyle.title_wrapper}>
        <h1>Контакти</h1>
      </div>
    </section>
  )
}

export default ContactsHeroSection
