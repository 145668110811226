import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import ContactsTemplate from "../modules/contacts/Contacts.template"
import { graphql } from "gatsby"
import ContactsHeroSection from "../modules/contacts/contactsHeroSection/ContactsHeroSection"

const ContactsPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={"Контакти"}
        keywords={
          "СОТ Ямбол, охрана Ямбол, СОД ямбол, Видеонаблюдение,охрана на обекти Ямбол, физическа охрана Ямбол, цени на СоТ Ямбол"
        }
      />
      <ContactsHeroSection
        contactsHeroSectionImage={data.contactsHeroSectionImage.edges}
      />
      <ContactsTemplate />
    </Layout>
  )
}

export default ContactsPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    contactsHeroSectionImage: allFile(
      filter: { relativeDirectory: { eq: "contactsHeroSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
